import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./pubmatic.module.scss"
import PubmaticChart from "../../assets/images/projects/pubmatic-chart.png"

const firstListItems = [
  "projects.pubmatic.solution-description.list.first-text",
  "projects.pubmatic.solution-description.list.second-text",
  "projects.pubmatic.solution-description.list.third-text",
  "projects.pubmatic.solution-description.list.fourth-text",
]

const secondListItems = [
  {
    title: "projects.pubmatic.solution-description.list-2.first-title",
    text: "projects.pubmatic.solution-description.list-2.first-text",
  },
  {
    title: "projects.pubmatic.solution-description.list-2.second-title",
    text: "projects.pubmatic.solution-description.list-2.second-text",
  },
  {
    title: "projects.pubmatic.solution-description.list-2.third-title",
    text: "projects.pubmatic.solution-description.list-2.third-text",
  },
  {
    title: "projects.pubmatic.solution-description.list-2.fourth-title",
    text: "projects.pubmatic.solution-description.list-2.fourth-text",
  },
]

const technologyBackgroundItems = [
  {
    titlePath: "projects.pubmatic.technology-background.list.first-item.title",
    textPath: "projects.pubmatic.technology-background.list.first-item.text",
  },
  {
    titlePath: "projects.pubmatic.technology-background.list.second-item.title",
    textPath: "projects.pubmatic.technology-background.list.second-item.text",
  },
  {
    titlePath: "projects.pubmatic.technology-background.list.third-item.title",
    textPath: "projects.pubmatic.technology-background.list.third-item.text",
  },
  {
    titlePath: "projects.pubmatic.technology-background.list.forth-item.title",
    textPath: "projects.pubmatic.technology-background.list.forth-item.text",
  },
]

const resultsItems = [
  "projects.pubmatic.results.list.first-text",
  "projects.pubmatic.results.list.second-text",
  "projects.pubmatic.results.list.third-text",
]

const Pubmatic: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const solutionDescriptionClasses = classNames(
    componentStyles.solutionDescription,
    "container"
  )
  const solutionDescriptionTitleClasses = classNames(
    componentStyles.solutionDescriptionTitle,
    "large-section-title"
  )
  const numberedListClasses = classNames(
    componentStyles.solutionDescriptionNumberedList,
    "grid-4"
  )
  const titledListClasses = classNames(
    componentStyles.solutionDescriptionNumberedList,
    componentStyles.solutionDescriptionTitledList,
    "grid-4"
  )

  const technologyBackgroundClasses = classNames(
    componentStyles.technologyBackground,
    "container"
  )
  const technologyBackgroundTitleClasses = classNames(
    componentStyles.technologyBackgroundTitle,
    "large-section-title"
  )
  const tilesListClasses = classNames(
    componentStyles.technologyBackgroundTilesList,
    "grid-4"
  )

  const forecastingAccuracyAnalysisClasses = classNames(
    componentStyles.forecastingAccuracy,
    "container"
  )

  const resultsClasses = classNames(componentStyles.results, "container")
  const resultsTitleClasses = classNames(
    componentStyles.resultsTitle,
    "large-section-title"
  )
  const resultsCubeListClasses = classNames(
    componentStyles.resultsCubeList,
    "grid-3"
  )

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.pubmatic.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.pubmatic.seo.title" })}
        pathname={location.pathname}
      >
        <meta name="robots" content="noindex" />
      </SEO>
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <div className={componentStyles.bannerWrapper}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                id: "projects.pubmatic.banner.title",
              })}
            </h1>
            <h4 className={componentStyles.bannerCompanyTitle}>
              {intl.formatMessage({
                id: "projects.pubmatic.banner.client-company",
              })}
            </h4>
            <p className={componentStyles.bannerCompanyName}>
              {intl.formatMessage({
                id: "projects.pubmatic.banner.client-name",
              })}
            </p>
          </div>
        </div>
      </section>
      <section className={solutionDescriptionClasses}>
        <div className={componentStyles.solutionDescriptionTextBlock}>
          <h2 className={solutionDescriptionTitleClasses}>
            {intl.formatMessage({
              id: "projects.pubmatic.solution-description.title",
            })}
          </h2>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.pubmatic.solution-description.paragraph-1",
            })}
          </p>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.pubmatic.solution-description.paragraph-2",
            })}
          </p>
        </div>
        <ol className={numberedListClasses}>
          {firstListItems.map(translatePath => (
            <li
              key={translatePath}
              className={componentStyles.solutionDescriptionNumberedListItem}
            >
              <p
                className={
                  componentStyles.solutionDescriptionNumberedListItemText
                }
              >
                {intl.formatMessage({
                  id: translatePath,
                })}
              </p>
            </li>
          ))}
        </ol>
        <div className={componentStyles.solutionDescriptionTextBlock}>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.pubmatic.solution-description.paragraph-3",
            })}
          </p>
        </div>
        <ul className={titledListClasses}>
          {secondListItems.map(({ title, text }) => (
            <li
              key={title}
              className={componentStyles.solutionDescriptionNumberedListItem}
            >
              <h4
                className={componentStyles.solutionDescriptionTitledListTitle}
              >
                {intl.formatMessage({
                  id: title,
                })}
              </h4>
              <p
                className={
                  componentStyles.solutionDescriptionNumberedListItemText
                }
              >
                {intl.formatMessage({
                  id: text,
                })}
              </p>
            </li>
          ))}
        </ul>
      </section>
      <section className={technologyBackgroundClasses}>
        <h2 className={technologyBackgroundTitleClasses}>
          {intl.formatMessage({
            id: "projects.pubmatic.technology-background.title",
          })}
        </h2>
        <ul className={tilesListClasses}>
          {technologyBackgroundItems.map(({ textPath }) => (
            <li
              key={textPath}
              className={componentStyles.technologyBackgroundTilesItem}
            >
              {intl.formatMessage({
                id: textPath,
              })}
            </li>
          ))}
        </ul>
      </section>
      <section className={forecastingAccuracyAnalysisClasses}>
        <h2 className={resultsTitleClasses}>
          {intl.formatMessage({
            id: "projects.pubmatic.forecasting-accuracy.title",
          })}
        </h2>
        <p className={componentStyles.resultsText}>
          {intl.formatMessage({
            id: "projects.pubmatic.forecasting-accuracy.paragraph-1",
          })}
        </p>
        <div className={componentStyles.resultsImageWrapper}>
          <img
            src={PubmaticChart}
            className={componentStyles.resultsImage}
            alt={intl.formatMessage({
              id: "projects.pubmatic.forecasting-accuracy.image-alt",
            })}
            width="1150"
            height="450"
          />
        </div>
        <p className={componentStyles.forecastingAccuracyText}>
          {intl.formatMessage({
            id: "projects.pubmatic.forecasting-accuracy.paragraph-2",
          })}
        </p>
      </section>
      <section className={resultsClasses}>
        <h2 className={resultsTitleClasses}>
          {intl.formatMessage({
            id: "projects.pubmatic.results.title",
          })}
        </h2>
        <p className={componentStyles.resultsText}>
          {intl.formatMessage({
            id: "projects.pubmatic.results.text",
          })}
        </p>
        <ul className={resultsCubeListClasses}>
          {resultsItems.map(textPath => (
            <li key={textPath} className={componentStyles.resultsCubeItem}>
              {intl.formatMessage({
                id: textPath,
              })}
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  )
}
export default Pubmatic
